.PlayerAspectRatio {
  max-height: 95vh;
  overflow-y: scroll;
}

.PlayerAspectRatio:before {
  padding-bottom: 56.25%;
  content: "";
  float: left;
}

.PlayerAspectRatio:after {
  display: table;
  content: "";
  clear: both;
}
