.enter {
  @apply transition ease-out duration-200 transform opacity-0 scale-95;
}

.enterActive,
.enterDone {
  @apply transform opacity-100 scale-100;
}

.exit {
  @apply transition ease-in duration-75 transform opacity-100 scale-100;
}

.exitActive,
.exitDone {
  @apply transform opacity-0 scale-95;
}
