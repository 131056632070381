/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */

@import "tailwindcss/utilities";

@import "./styles/socialshare.css";

/* purgecss start ignore */
@import "./styles/tenant-injected-html.css";
@import "./styles/nprogress.css";
@import "./styles/color-palettes.css";
@import "./styles/shaka-controls.css";
@import "./styles/bitmovin-player.css";
@import "./styles/player-aspect-ratio.css";

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

/* Left align sliders */
.slick-list > .slick-track {
  margin-left: 0;
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: 100% !important;
}

.line-clamp-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-8-lines {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.min-h-55vw {
  min-height: 55vw;
}

@screen sm {
  .sm\:aspect-auto-16-by-9 {
    height: 0;
    padding-bottom: 56.25%;
  }
}

/* Sliders */
.slider-prev-background {
  background: -moz-linear-gradient(
    right,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: -webkit-linear-gradient(
    right,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: linear-gradient(
    to left,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
}
.slider-next-background {
  background: -moz-linear-gradient(
    left,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: -webkit-linear-gradient(
    left,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: linear-gradient(
    to right,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
}
.slider-up-background {
  background: -moz-linear-gradient(
    bottom,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: -webkit-linear-gradient(
    bottom,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: linear-gradient(
    to top,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
}
.slider-down-background {
  background: -moz-linear-gradient(
    top,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: -webkit-linear-gradient(
    top,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
  background: linear-gradient(
    to bottom,
    var(--color-palette-gray-800-transparent) 0%,
    var(--color-palette-gray-800) 100%
  );
}
.slider-muted-prev-background {
  background: -moz-linear-gradient(
    right,
    var(--color-palette-gray-700-transparent) 0%,
    var(--color-palette-gray-700) 100%
  );
  background: -webkit-linear-gradient(
    right,
    var(--color-palette-gray-700-transparent) 0%,
    var(--color-palette-gray-700) 100%
  );
  background: linear-gradient(
    to left,
    var(--color-palette-gray-700-transparent) 0%,
    var(--color-palette-gray-700) 100%
  );
}

.slick-list {
  overflow: visible;
  width: 90%;
}
@screen sm {
  .slick-list {
    overflow: hidden;
    width: 100%;
  }
}

.kep-login-facebook.small {
  @apply rounded-lg px-3 py-1 text-sm normal-case bg-blue-600 border-blue-600 transition ease-in-out duration-150 !important;
}
.kep-login-facebook.small:before {
  content: " ";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjBBIJCwnGbaC4AAACd0lEQVRYw9WYT2sTURTFz4wTosIIJmAXhSoFg2SrC6GopCBko610k537rhVxJRWx+QB+Ae1KoQhxo0UoVKpIBEVDI8Ys3OnCCEYKkpDj4nXIzGTezJ3MzMIzq8l79/7ev3vnvhhEtrJEfS6iijJKKMAG0MdPdLCH59jBMNKaYU+OK9xgjzr1uMEV5sJ86JsM1tilRF3WaMQFVNgUOXfUZEUOMLkey7mjdZoSgM3GVO5JskE7CjDH1tTuSbLFuTCAndC9Qtg6gJlgcbwL5doLwxXJddwShN0Ib7GDb/gFA3nYOI4ZrPr61HF7MtAqotFt8kzAuZtUxb9Ehujc39BEzaSaTug5XWoC9w+0UR+kmhuQEySFHo/FAnRVjjIBAEuYj9zch/gdK0/P4+o4XS8JDLZ877M4h9mDAQZrGZvqFFkhCXksb4Re475gUS21B4sC9+RRD+CTyGZR7UFVtKb7nrfTIpsqYAIox9o8pbyoV1kBSlMAZCopQCEzQEEB7MwAtgIMtB0M16NveaK1HyjAj4TjPKVt+f5fAI7gRBSgndH4gY4CbMVwFw/wQgF28ScB4KS2pY9dla4H2MaVwC7u2t7Qtuj00jmmwKNEi6TT44OhEDTZjky90R9Jrz6r6kjNYIR66uO/h9F4BqDFr6nO4AsP+UvHBQ5TAwx5Kag2XUsNcCe4+LX4OhXAtrv49ZoV+SExoMWZsAtIke8TAd6xGHWFKvDN1IBXk+VlUKVpcS3gREUBBrzPvOQSqJ7z7MQCfOTZYE86AJjjde6JAG2u6m/7eoC6lizzGftawF82eFl/y48GOHO5wLu+357yJhd4ONrayPrvnH8PRfVZmihfvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNC0xOFQxNjoxMTowOS0wNzowMAR/UMMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDQtMThUMTY6MTE6MDktMDc6MDB1Iuh/AAAAAElFTkSuQmCC");
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 5px 8px 5px 0;
  vertical-align: -10px;
}
.kep-login-facebook.small:hover {
  @apply bg-blue-500 border-blue-500 !important;
}
.kep-login-facebook.small:focus,
.kep-login-facebook.small:active {
  @apply outline-none shadow-outline-blue bg-blue-700 border-blue-700 !important;
}

/* chat */
.ChatWidget .str-chat {
  max-height: 60vh !important;
}
@screen sm {
  .ChatWidget .str-chat {
    max-height: 90vh !important;
  }
}

.str-chat__header-livestream-right {
  display: none;
}

.dark.str-chat .str-chat__message-actions-box button:hover {
  color: #b3b3b3;
}

.str-chat__small-message-input-emojiselect,
.str-chat__small-message-input .rfu-file-upload-button {
  bottom: 14px;
}

.ChatWidget .str-chat__thread {
  min-width: 0 !important;
}

.ChatWidget .str-chat-channel .str-chat__container .str-chat__main-panel {
  max-width: 100%;
}

/* width */
.scrollbarred::-webkit-scrollbar {
  @apply z-10 w-3;
}

.scrollbarred::-webkit-scrollbar-track {
  background-color: var(--color-palette-gray-700);
}

.scrollbarred::-webkit-scrollbar-thumb {
  background-color: var(--color-palette-gray-500);
  border-top: 1.5rem var(--color-palette-gray-700) solid;
  background-clip: padding-box;
}

/* Handle on hover */
.scrollbarred::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-palette-gray-600);
}

/* Azure media player modifications */
.amp-flush-skin .vjs-error .vjs-error-display {
  @apply flex items-center justify-center !important;
}

.vjs-modal-dialog-content {
  @apply relative top-0 right-0 !important;
  left: 35px !important;
  max-width: calc(100% - 105px) !important;
}
/* purgecss end ignore */

/* react-add-to-calendar */
.react-add-to-calendar__dropdown {
  @apply absolute;
}
.react-add-to-calendar__dropdown a {
  @apply block px-4 py-2;
}
.react-add-to-calendar__dropdown a:hover {
  background-color: var(--color-palette-gray-100);
}
/* react-add-to-calendar */

/* bitmovin ff-rwd styles */
.bpmui-hidden-ff-rwd {
  visibility: visible !important;
  opacity: 1 !important;
}

/* localizejs - using <var/> to mark variables but browser by default makes italic */
var {
  font-style: normal;
}
