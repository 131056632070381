#evPlayer .bmpui-ui-hugeplaybacktogglebutton:focus {
  box-shadow: none !important;
}

#evPlayer .bmpui-ui-watermark {
  display: none;
}

#evPlayer .bmpui-ui-airplaytogglebutton:focus,
#evPlayer .bmpui-ui-audiotracksettingstogglebutton:focus,
#evPlayer .bmpui-ui-button:focus,
#evPlayer .bmpui-ui-casttogglebutton:focus,
#evPlayer .bmpui-ui-clickoverlay:focus,
#evPlayer .bmpui-ui-closebutton:focus,
#evPlayer .bmpui-ui-fullscreentogglebutton:focus,
#evPlayer .bmpui-ui-hugeplaybacktogglebutton:focus,
#evPlayer .bmpui-ui-hugereplaybutton:focus,
#evPlayer .bmpui-ui-listbox .bmpui-ui-listbox-button:focus,
#evPlayer .bmpui-ui-piptogglebutton:focus,
#evPlayer .bmpui-ui-playbacktogglebutton:focus,
#evPlayer .bmpui-ui-settingspanelpagebackbutton:focus,
#evPlayer .bmpui-ui-settingspanelpageopenbutton:focus,
#evPlayer .bmpui-ui-settingstogglebutton:focus,
#evPlayer .bmpui-ui-button-ad-skip:focus,
#evPlayer .bmpui-ui-subtitlesettingsresetbutton:focus,
#evPlayer .bmpui-ui-subtitlesettingstogglebutton:focus,
#evPlayer .bmpui-ui-volumetogglebutton:focus,
#evPlayer .bmpui-ui-vrtogglebutton:focus,
#evPlayer .bmpui-ui-watermark:focus {
  box-shadow: none;
  outline: none;
  filter: drop-shadow(0px 0px 6px #fff);
}

#evPlayer .bmpui-ui-seekbar:focus,
#evPlayer .bmpui-ui-volumeslider:focus {
  box-shadow: none;
  outline: none;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}

#evPlayer .bmpui-ui-selectbox:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  outline: none;
}

#evPlayer .bmpui-seekbar-playbackposition-marker {
  background-color: #1fabe2;
}

#evPlayer .bmpui-ui-settings-panel {
  z-index: 99999999;
}

#evPlayer .bmpui-ui-recommendation-overlay {
  display: none !important;
}

/* Better caption defaults */
#evPlayer .bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay {
  font-size: 2em;
  bottom: 2em;
}
#evPlayer
  .bmpui-ui-uicontainer
  .bmpui-ui-subtitle-overlay.bmpui-controlbar-visible {
  bottom: 4em;
}

/* Full screen mode */
#evPlayer .bmpui-container-wrapper {
  font-size: 1.1em;
}
#evPlayer .bmpui-ui-playbacktimelabel {
  line-height: 21px;
}
#evPlayer .bmpui-ui-settingspanelpageopenbutton {
  vertical-align: 0px;
}

/* Generic player button style */
.Eventive-playerbutton:hover {
  -webkit-filter: drop-shadow(0 0 1px #fff);
  filter: drop-shadow(0 0 1px #fff);
}
.Eventive-playerbutton:focus {
  box-shadow: none;
  outline: none;
  -webkit-filter: drop-shadow(0px 0px 6px #fff);
  filter: drop-shadow(0px 0px 6px #fff);
}

/* Prevent subtitle styles (e.g. italics, bold) from being undone by bitmovin player */
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay * {
  all: revert;
}
