/**
 * @license
 * Copyright 2016 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.shaka-hidden {
  display: none !important;
}
.shaka-video-container {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.shaka-video-container .material-icons-round {
  font-family: "Material Icons Round";
  font-size: 24px;
}
.shaka-video-container * {
  font-family: Roboto-Regular, Roboto, sans-serif;
}
.shaka-video-container:fullscreen {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.shaka-video-container:fullscreen .shaka-text-container {
  font-size: 4.4vmin;
}
.shaka-video-container:-webkit-full-screen {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.shaka-video-container:-webkit-full-screen .shaka-text-container {
  font-size: 4.4vmin;
}
.shaka-video-container:-moz-full-screen {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.shaka-video-container:-moz-full-screen .shaka-text-container {
  font-size: 4.4vmin;
}
.shaka-video-container:-ms-fullscreen {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.shaka-video-container:-ms-fullscreen .shaka-text-container {
  font-size: 4.4vmin;
}
.shaka-controls-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
}
.shaka-video-container:not([shaka-controls="true"]) .shaka-controls-container {
  display: none;
}
.shaka-controls-container * {
  flex-shrink: 0;
}
.shaka-controls-container[casting="true"] .shaka-fullscreen-button {
  display: none;
}
.shaka-bottom-controls {
  width: 100%;
  padding: 12px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.shaka-controls-button-panel {
  padding: 0;
  margin: 0;
  order: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  min-width: 48px;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  opacity: 0;
  transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
}
.shaka-controls-container[casting="true"] .shaka-controls-button-panel,
.shaka-controls-container[shown="true"] .shaka-controls-button-panel {
  opacity: 1;
}
.shaka-controls-button-panel > * {
  color: #fff;
  height: 32px;
  margin: 1px 6px;
  padding: 0;
  background: 0 0;
  border: 0;
  cursor: pointer;
}
.shaka-controls-button-panel > .shaka-cast-button {
  font-size: 0.9em;
  padding-top: 2px;
}
.shaka-play-button-container {
  margin: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shaka-scrim-container {
  margin: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
  background: linear-gradient(to top, #000 0, rgba(0, 0, 0, 0) 15%);
}
.shaka-controls-container[casting="true"] .shaka-scrim-container,
.shaka-controls-container[shown="true"] .shaka-scrim-container {
  opacity: 1;
}
.shaka-text-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 10%;
  width: 100%;
  min-width: 48px;
  text-align: center;
  transition: bottom cubic-bezier(0.4, 0, 0.6, 1) 0.1s;
  transition-delay: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  line-height: 1.4;
}
.shaka-text-container * {
  line-height: 1.4;
}
.shaka-text-container div {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: inline-block;
}
.shaka-text-container .shaka-nested-cue:not(:last-of-type):after {
  content: " ";
  white-space: pre;
}
.shaka-controls-container[shown="true"] ~ .shaka-text-container {
  bottom: 15%;
  transition-delay: 0s;
}
.shaka-text-container span {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: inline-block;
}
.shaka-spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shaka-video-container:not([shaka-controls="true"]) .shaka-spinner-container {
  display: none;
}
.shaka-spinner {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 32px;
  width: 0;
  height: 0;
}
.shaka-play-button {
  box-sizing: border-box;
  padding: 30px;
  width: 0;
  height: 0;
  margin: 0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px 0;
  border: none;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #00b3ff;
  opacity: 0.9;
}
.shaka-play-button[icon="play"] {
  background-image: url(data:image/svg+xml,%3Csvg%20fill%3D%22%23ffffff%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M8%205v14l11-7z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E);
}
.shaka-play-button[icon="pause"] {
  display: none;
  /* background-image: url(data:image/svg+xml,%3Csvg%20fill%3D%22%23ffffff%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M6%2019h4V5H6v14zm8-14v14h4V5h-4z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E); */
}
.shaka-play-button:focus,
.shaka-play-button:hover {
  opacity: 1;
}
.shaka-current-time {
  font-size: 14px;
  color: #fff;
  height: 20px;
  cursor: pointer;
}
.shaka-current-time[disabled] {
  background-color: transparent;
  color: #fff;
  cursor: default;
}
.shaka-controls-container button:focus,
.shaka-controls-container input:focus {
  outline: 1px solid Highlight;
  outline: 1px solid -webkit-focus-ring-color;
}
.shaka-controls-container button:-moz-focus-inner,
.shaka-controls-container input:-moz-focus-outer {
  outline: 0;
  border: 0;
}
.shaka-controls-container:not(.shaka-keyboard-navigation) button:focus,
.shaka-controls-container:not(.shaka-keyboard-navigation) input:focus {
  outline: 0;
}
.shaka-mobile .shaka-volume-bar-container {
  display: none;
}
.shaka-range-container {
  position: relative;
  top: 0;
  left: 0;
  margin: 6px;
  height: 8px;
  border-radius: 8px;
  background: #fff;
  order: 0;
}
.shaka-volume-bar-container {
  width: 100px;
  top: 0px;
}
@media screen and (max-width: 550px) {
  .shaka-volume-bar-container {
    display: none;
  }
}
.shaka-range-element {
  -webkit-appearance: none;
  background: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  height: 16px;
  top: -4px;
  z-index: 1;
  cursor: pointer;
}
.shaka-range-element::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  background: 0 0;
  color: transparent;
  border: none;
}
.shaka-range-element::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  border-radius: 16px;
  height: 16px;
  width: 16px;
  background: #fff;
}
.shaka-range-element::-moz-range-track {
  width: 100%;
  height: 16px;
  background: 0 0;
  color: transparent;
  border: none;
}
.shaka-range-element::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  border-radius: 16px;
  height: 16px;
  width: 16px;
  background: #fff;
}
.shaka-range-element::-ms-track {
  width: 100%;
  height: 16px;
  background: 0 0;
  color: transparent;
  border: none;
}
.shaka-range-element::-ms-thumb {
  -webkit-appearance: none;
  border: none;
  border-radius: 16px;
  height: 16px;
  width: 16px;
  background: #fff;
}
.shaka-range-element::-ms-tooltip {
  display: none;
}
.shaka-range-element::-ms-fill-lower {
  display: none;
}
.shaka-range-element::-ms-fill-upper {
  display: none;
}
.shaka-seek-bar-container {
  opacity: 0;
  transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
}
.shaka-controls-container[casting="true"] .shaka-seek-bar-container,
.shaka-controls-container[shown="true"] .shaka-seek-bar-container {
  opacity: 1;
}
.shaka-spinner-svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.shaka-spinner-path {
  stroke: #ffffff;
  stroke-dasharray: 20, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.shaka-spacer {
  cursor: default;
  flex-shrink: 1;
  flex-grow: 1;
  margin: 0;
}
.shaka-settings-menu {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  background: #fff;
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  max-height: 250px;
  min-width: 180px;
  opacity: 0;
  transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 0.6s;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 30px;
}
.shaka-controls-container[casting="true"] .shaka-settings-menu,
.shaka-controls-container[shown="true"] .shaka-settings-menu {
  opacity: 1;
}
.shaka-settings-menu button {
  font-size: 14px;
  background: 0 0;
  color: #000;
  border: none;
  min-height: 30px;
  padding: 3.5px 6px;
  display: flex;
  align-items: center;
}
.shaka-settings-menu button:hover {
  background: #e0e0e0;
}
.shaka-keyboard-navigation .shaka-settings-menu button:focus {
  background: #e0e0e0;
}
.shaka-settings-menu i {
  padding-left: 10px;
  padding-right: 10px;
}
.shaka-settings-menu.shaka-low-position {
  bottom: 15px;
}
.shaka-overflow-menu span {
  text-align: left;
}
.shaka-overflow-button-label {
  position: relative;
  display: flex;
  flex-direction: column;
}
.shaka-current-selection-span {
  color: rgba(0, 0, 0, 0.54);
}
.shaka-audio-languages span,
.shaka-playback-rates span,
.shaka-resolutions span,
.shaka-text-languages span {
  margin-left: 54px;
}
.shaka-back-to-overflow-button span {
  margin-left: 0;
}
.shaka-back-to-overflow-button i {
  padding-right: 20px;
}
.shaka-auto-span {
  left: 17px;
}
.shaka-captions-on {
  color: #000;
}
.shaka-captions-off {
  color: grey;
}
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v24/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmM.otf)
    format("opentype");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.shaka-cast-receiver-name {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 150%;
  padding: 5px;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
}
.shaka-cast-connected-button {
  display: none;
}
.shaka-hidden,
button.shaka-hidden {
  display: none;
}
.shaka-displayed,
button.shaka-displayed {
  display: flex;
} /*# sourceMappingURL=controls.css.map */
